.success {
  padding: 56px 20px;
  min-height: 100%;
  width: 100%;
}

.title {
  text-align: center;
}

.wrapper {
  margin-top: 80px;
}