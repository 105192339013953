/* Wrapper */
.wrapper {
    /* text-align: right; */
    width: 100%;
}

.scale_left {
    font-size: 12px;
    position: relative;
    right: 10px;
    top: 7px;
}

.scale_right {
    font-size: 12px;
    position: relative;
    left: 10px;
    top: 7px;
}

/* Slider */
.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    top: 13px;
    height: 14px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    top: -6px;
    height: 26px;
    width: 26px;
    left: 0px;
    bottom: -3px;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.125);
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: #1890ff;
}

input:focus + .slider {
    box-shadow: 0 0 1px #1890ff;
}

input:checked + .slider:before {
    -webkit-transform: translateX(14px);
    -ms-transform: translateX(14px);
    transform: translateX(14px);
}
/* end of slider */
