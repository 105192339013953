.wrapper {
  margin-top: 56px;
}

/* https://stackoverflow.com/questions/20590239/maintain-aspect-ratio-of-div-but-fill-screen-width-and-height-in-css/36295495#20593342 */
.mapContainer {
  width: 75vw; /* 75% of viewport vidth */
  height: 65.6043956vw; /* ratio = 398/455 * 75 = 65.6043956 */
  max-height: 75vh;
  max-width: 85.741206vh; /* 455/398 * 75 = 85.741206 */

  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

/* positions the div containing the cluster list on the image, in a relative matter so that it follows scaling */
.clusterContainer {
  width: 75vw; /* 75% of viewport vidth */
  height: 65.6043956vw; /* ratio = 398/455 * 75 = 65.6043956 */
  max-height: 75vh;
  max-width: 85.741206vh; /* 455/398 * 75 = 85.741206 */

  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* top: 38%;
    left: 38%;
    width: 63.7%;
    height: 10.2%; */

  /* display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around; */
}

.card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.squre {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 35px;
  height: 17px;
  background: rgb(245, 243, 243);
  color: red;
  border-radius: 30px;  
  margin-right: 10px;
}
