.card_body {
	float: left;
	width: 70%;
	padding-bottom: 12px;
	font-size: 13px;
}

.card_date_wrapper {
	float: left;
	width: 30%;
	text-align: right;
}

.card_date {
	font-size: 9px;
	position: relative;
	top: -3px;
}

/* Accordion */
.accordion {
	width: 100%;
	padding: 0px; 
	margin-left: 4px;
	margin-bottom: 0px;
}

.toggle {
	color: #FFFFFF;
	border: none;
	background-color: #fff;
}

.collapse {
	width: 100%;
	padding: 0px;
}

.div_title {
	float: left;
	width: 95%;
	color: #707070;
}

.title {
	padding-left: 10px;
	font-size: 13px;
	font-weight: 800;
}

.arrow {
	float: left;
	width: 5%;
	text-align: right;
	color: rgb(112, 112, 112);
}

/* End of Accordion */