.corner {
  border-radius: 25px;
  border: 2px solid #0d0e0d;
  padding: 10px;
  /* width: 200px;
  height: 150px; */
}

.box {
  border: 3px solid rgb(177, 175, 175);
  border-style: dotted;
  padding: 2;
  margin: 2;
  cursor: pointer;
}
