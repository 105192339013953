.w_wrapper {
  padding: 10px 10px 10px 10px;
  background-color: white;
  width: 100%;
  height: 100%;
}

.w_container {
  width: 100%;
  height: 100%;
}

.w_start {
  width: 100%;
  height: 15%;
}

.w_title {
  font-size: 20px;
  text-align: left;
  word-wrap: break-word;
  width: 90%;
  margin-left: 15px;
}

.w_setting {
  top: 10px;
  right: 10px;
  position: absolute;
}



.w_unit {
  font-size: 30px;
  font-weight: 200;
}

.w_subtitle {
  position: relative;
  top: 10px;
  font-size: 20px;
  font-weight: 200;
  display: block;
}

.w_start {
  height: 15%;
}

.w_middle {
  height: 70%;
  line-height: 1em;
  position: relative; 
}

.w_value {
  font-size: 50px;
  font-weight: 500;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  margin-top: -0.3em;
  margin-left: -50%;
  text-align: center; 
}

.w_end {
  position: absolute;
  bottom: 0px;
  margin-bottom: 15px;
  text-align: left;
}