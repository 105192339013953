.success {
  padding: 56px 20px;
  min-height: 100%;
  width: 100%;
}

.title {
  text-align: center;
}

.active {
  font-size: 11px;
  font-weight: 400;
  background-color: #9cc96b;
  color: #fff;
}

.inactive {
  font-size: 11px;
  font-weight: 400;
  background-color: #E7E955;
  color: #404044;
}

.error {
  font-size: 11px;
  font-weight: 400;
  background-color: #e44959;
  color: #fff;
}

.btnContainer:hover {
  background-color: rgb(247, 248, 252);
  cursor: pointer;
}