.card {
    width: 200px;
    margin: 10px; 

    height: 200px;
}

.cardText {
    font-size: '14px';
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space:nowrap;
    height: 2em;
}

.cardBody {

    padding: 10px 20px 10px 20px;
}

.cardImage {
    height: 100px;
    width: 100px;
}

.cardContent {
    background: #ffffff;
    padding: 20px;
    width: 100%;
    height: 100%;
    
    display: flex;
    justify-content: center;
}