.success {
	padding: 56px 20px;
	min-height: 100%;
	width: 100%;
}

.title {
	text-align: center;
}

/* The Modal (background) */
.modal {
	display: none;
	position: fixed;
	z-index: 100;
	padding-top: 100px;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgb(0, 0, 0);
	background-color: rgba(0, 0, 0, 0.4);
}

.modal_content {
	background-color: #fefefe;
	margin: auto;
	padding: 20px;
	border: 1px solid #888;
	width: 500px;
	height: 330px;
}

.close {
	position: relative;
	top: -20px;
	right: 0px;
	color: #aaaaaa;
	/* float: right; */
	font-size: 28px;
	font-weight: bold;
}

.close:hover,
.close:focus {
	color: #000;
	text-decoration: none;
	cursor: pointer;
}

/* End of Modal*/

.active {
	font-size: 11px;
	font-weight: 400;
	background-color: #9cc96b;
	color: #fff;
}

.inactive {
	font-size: 11px;
	font-weight: 400;
	background-color: #e44959;
	color: #fff;
}