.wrapper {
	width: 100%;
	height: 200px
}

.w_title {
	font-size: 20px;
	/* padding-left: 10px; */
	padding-bottom: 10px;
	text-align: left;
	word-wrap: break-word;
	width: 90%;
	/* margin-left: 15px; */
  }

/* Label styles */
.label {
	padding: 0.3em 0.6em;
	border-radius: 0.26em;
	white-space: nowrap;
  }

  .default {
	background-color: #0093b5;
	color: white;
  }
  
  .warning {
	background-color: rgba(221, 223, 13, 0.7);
	color: #404040;
  }
  
  .critical {
	background-color: #e44959;
	color: #fff;
  }
  
  .success {
	background-color: #9cc96b;
	color: #fff;
  }
  
  /* The Modal (background) */
.modal {
	display: none;
	position: fixed;
	z-index: 100;
	padding-top: 100px;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgb(0, 0, 0);
	background-color: rgba(0, 0, 0, 0.4);
  }
  
  .modal_content {
	background-color: #fefefe;
	margin: auto;
	padding: 20px;
	border: 1px solid #888;
	width: 300px;
  }
  
  .close {
	position: absolute;
	top: -20px;
	right: 10px;
	color: #aaaaaa;
	/* float: right; */
	font-size: 28px;
	font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
	color: #000;
	text-decoration: none;
	cursor: pointer;
  }
  /* End of Modal*/