.card_body {
    float: left;
    width: 70%;
    font-size: 13px;
    top: 2px;
    position: relative;
}

.card_date_wrapper {
    float: left;
    width: 30%;
    text-align: right;
    font-size: 12px;
    height: 100%;
    /* top: 6px; */
}

.card_date {
    font-size: 9px;
    position: relative;
    top: 2px;
}

.card_icon {
    margin-left: 10px;
    position: relative;
    top: 3px;
}

/* Accordion */
.toggle {
    background-color: #f05622;
    color: #ffffff;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

.collapse {
    background-color: #f05623;
    color: #ffffff;
    opacity: 0.8;
    height: 45px;
}

.div_title {
    float: left;
    width: 95%;
    padding-left: 10px;
}

.title {
    padding-left: 10px;
    font-size: 13px;
    font-weight: 800;
}

.arrow {
    float: left;
    width: 5%;
    text-align: right;
    padding-right: 10px;
}
/* Emd of Accordion */
