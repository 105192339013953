.qrcodescan {
  padding: 56px 20px;
  min-height: 100%;
  width: 100%;
}

.title {
  text-align: center;
  margin-top: 55px;
  padding-left: 0px;
  padding-right: 0px;
}

video {
  height: 100%;
  width: 100%;
}
