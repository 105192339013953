/* Wrapper */
.wrapper {
    margin: 0px;
    height: 100%;
}

/* Title */
.title_wrapper {
    width: 100%;
}

.title_icon {
    color: #d3753b;
}

.title {
    font-family: Days One;
    font-size: 15px;
    text-align: center;
    border: none;
    width: 250px;
    color: rgb(105, 104, 104);
}

.title:focus {
    outline: none;
}

.div_input {
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
    color: #707070;
}

/* Measurements & Chart  */
.m_wrapper {
    margin: 0px;
    width: 100%;
    height: 120px;
}

/* Values */
.temp_wrapper {
    padding-top: 20px;
}

.temp {
    border: 0;
    border-bottom: 1px solid rgb(167, 167, 167);
    outline: 0;
    width: 60px;
    text-align: right;
    color: #707070;
}

.value {
    text-align: center;
    font-size: 21px;
}

.temp_low_name {
    font-size: 12px;
    font-weight: 800;
    color: #60bec9;
}

.temp_high_name {
    font-size: 12px;
    font-weight: 800;
    color: #f05622;
}

.err_msg {
    color: red;
    font-size: 10px;
}

/* Half donut Chart */
.chart_wrapper {
    text-align: center;
    height: 130px;
}

.chart {
    position: relative;
    z-index: 1;
    height: 100px;
    width: 100%;
    top: -16px;
    align-content: center;
}

.scale {
    position: relative;
    z-index: 2;
    font-size: 39px;
    font-weight: 800;
    top: -78px;
    color: #707070;
}

.date {
    position: relative;
    z-index: 3;
    font-family: Open Sans;
    font-size: 11px;
    top: -78px;
    color: #000000;
}

.icon {
    position: relative;
    top: -150px;
    left: 100px;
    z-index: 3;
}
