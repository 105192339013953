.w_wrapper {
  /* padding: 10px 10px 10px 10px; */
  background-color: white;
  /* border-style: solid;
  border-width: 1px; */
  width: 100%;
  height: 100%;
}

.w_container {
  width: 100%;
  height: 100%;
}

.w_title {
  font-size: 20px;
  /* padding-left: 10px; */
  padding-bottom: 10px;
  text-align: left;
  word-wrap: break-word;
  width: 90%;
  /* margin-left: 15px; */
}

.w_table {
  width: 100%;
  height: 100%;
}

.w_start {
}

.w_end {
  height: 100%;
}

.root {
  background: red;
}