.title {
	font-size: 20px;
	text-align: left;
	word-wrap: break-word;
	width: 90%;
	margin-left: 15px;
	position: relative;
	top: -14px;
}

.content {
	position: absolute;
	top: 0px;
	width: 100%;
	height: 100%;
}

.image_container {
	padding-top: 30px;
	padding-left: 0px;
	padding-right: 0px;
	height: 100%;
}

.image {
	margin-left: 0px;
	width: 100%;
	height: 100%;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;
}

.measure_container {
	width: 180;
	height: 100%;
}

.value_container {
	width: 90;
}

.value {
	font-size: 42px;
	font-weight: 800;
}

.active {
	font-size: 20px;
	font-weight: 400;
	background-color: #9cc96b;
	color: #fff
}

.inactive {
	font-size: 11px;
	font-weight: 400;
	background-color: #e44959;
	color: #fff
}


.w_middle {
	height: 70%;
	line-height: 1em;
	position: relative;
}

.w_value {
	font-size: 50px;
	font-weight: 500;
	/* position: absolute; */
	/* top: 10px; */
	left: 50%;
	/* width: 100%; */
	/* border: 1px solid red; */
	/* margin-top: 0em; */
	/* margin-left: -50%; */
	text-align: center;
}

.w_unit {
	font-size: 30px;
	font-weight: 200;
}