.wrapper {
	/* border: 1px solid red; */
	width: 100%;
	height: 100%;
}

.w_title {
	font-size: 20px;
	/* padding-left: 10px; */
	padding-bottom: 10px;
	text-align: left;
	word-wrap: break-word;
	width: 90%;
	/* margin-left: 15px; */
}

/* Label styles */
.label {
	padding: 0.6em 0.6em;
	border-radius: 0.26em;
	white-space: nowrap;
	height: 2em;
}

.default {
	background-color: #0093b5;
	color: white;
}

.warning {
	background-color: rgba(221, 223, 13, 0.7);
	color: #404040;
}

.critical {
	background-color: #e44959;
	color: #fff;
}

.success {
	background-color: #9cc96b;
	color: #fff;
}

.in_progress {
	background-color: #e7e441;
	color: #5a5a40;
}

.ignored {
	background-color: #949495;
	color: #fff;
}